import { FiatCurrency } from '@/api/common.types';

export const BTC_NETWORK = 'https://www.blockchain.com/btc/tx/';
export const ETH_NETWORK = 'https://etherscan.io/tx/';
export const SUPPORT_EMAIL = 'mailto:support@relaypay.io';
export const MERCHANT_TERMS = 'https://relaypay.io/ecommerce-terms-and-conditions';
export const RELAYPAY_WEBSITE_HOME = 'https://www.relaypay.io/';
export const RELAYPAY_WEBSITE_BUSINESS = 'https://www.relaypay.io/business';
export const AVOID_SCAMS = 'https://www.relaypay.io/post/crypto-investment-scams-australia-how-to-avoid';

export const FIAT_CURRENCY_SUPPORTED_ON_BE = {
  [FiatCurrency.AUD]: { value: FiatCurrency.AUD, label: FiatCurrency.AUD },
  [FiatCurrency.USD]: { value: FiatCurrency.USD, label: FiatCurrency.USD },
  [FiatCurrency.EUR]: { value: FiatCurrency.EUR, label: FiatCurrency.EUR },
  [FiatCurrency.GBP]: { value: FiatCurrency.GBP, label: FiatCurrency.GBP },
  [FiatCurrency.AED]: { value: FiatCurrency.AED, label: FiatCurrency.AED },
  [FiatCurrency.CAD]: { value: FiatCurrency.CAD, label: FiatCurrency.CAD },
  [FiatCurrency.CHF]: { value: FiatCurrency.CHF, label: FiatCurrency.CHF },
  [FiatCurrency.CNY]: { value: FiatCurrency.CNY, label: FiatCurrency.CNY },
  [FiatCurrency.HKD]: { value: FiatCurrency.HKD, label: FiatCurrency.HKD },
  [FiatCurrency.INR]: { value: FiatCurrency.INR, label: FiatCurrency.INR },
  [FiatCurrency.JPY]: { value: FiatCurrency.JPY, label: FiatCurrency.JPY },
  [FiatCurrency.PHP]: { value: FiatCurrency.PHP, label: FiatCurrency.PHP },
  [FiatCurrency.SEK]: { value: FiatCurrency.SEK, label: FiatCurrency.SEK },
  [FiatCurrency.SGD]: { value: FiatCurrency.SGD, label: FiatCurrency.SGD },
  [FiatCurrency.THB]: { value: FiatCurrency.THB, label: FiatCurrency.THB },
  [FiatCurrency.TRY]: { value: FiatCurrency.TRY, label: FiatCurrency.TRY },
  [FiatCurrency.ZAR]: { value: FiatCurrency.ZAR, label: FiatCurrency.ZAR },
};

export const SELL_CHECKOUT_LOADER_TIME_IN_MS = 1000;
