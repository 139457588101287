// eslint-disable-next-line import/no-cycle
import { BankDetails, BankDetailsResponse } from '@/api';
import { CryptoCurrency } from '@/api/common.types';
import { BTC_NETWORK, ETH_NETWORK } from '@/constants/common';
import { ChainData } from '@/store/chainsData';
import { walletConnectSupportList } from './constants';

export const allowOnlyNumber = (value: string) => {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
};

export const allowOnlyNumberWithoutFloatComa = (value: string) => {
  return value.replace(/[^0-9]/g, '');
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;

  return emailRegex.test(email);
};

export const validateWalletAddress = (walletAddress: string) => {
  const regex = /^0x[a-fA-F0-9]{40}$/;

  const BTCRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;

  return regex.test(walletAddress) || BTCRegex.test(walletAddress);
};

export function formatDate(isoDateString?: string) {
  if (!isoDateString) {
    return isoDateString;
  }
  const date = new Date(isoDateString);
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
  return formatter.format(date);
}

export function formatDateAndTime(isoDateString?: string) {
  if (!isoDateString) {
    return { date: isoDateString, time: '' };
  }

  const date = new Date(isoDateString);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date);

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: 'numeric',
    hour12: true,
  }).format(date);
  return { date: formattedDate, time: formattedTime };
}

export function downloadJson(obj: Record<string, any>) {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(obj))}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = 'apiKeys.json';
  link.click();
}

export function openLink(href: string) {
  const link = document.createElement('a');
  link.href = href;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
}

export function getCryptoNameFromCryptoCurrency(cryptoCurrency?: CryptoCurrency) {
  switch (cryptoCurrency) {
    case CryptoCurrency.BTC: {
      return 'Bitcoin';
    }
    case CryptoCurrency.ETH: {
      return 'Ethereum';
    }
    default: {
      return cryptoCurrency;
    }
  }
}

export function formatNumberWithCommas(num?: string | number | null) {
  if (num === undefined || num === '' || num === null) return undefined;

  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(Number(num));
}

export function formatNumberWithMaxLength(num?: number | null, length?: number | null): number | undefined {
  if (num === undefined || num === null) return undefined;

  return Number(num.toFixed(length ?? 2));
}

export function truncateString({
  string,
  startAmount = 10,
  endAmount = 5,
}: {
  string?: string;
  startAmount?: number;
  endAmount?: number;
}): string {
  if (!string) {
    return '';
  }

  const maxLength = 20;
  const ellipsis = '...';

  if (string.length <= maxLength) {
    return string;
  }

  const start = string.slice(0, startAmount);
  const end = string.slice(-endAmount);

  return `${start}${ellipsis}${end}`;
}

export function formatSize(size: number): string {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  let formattedSize = size;
  let unitIndex = 0;

  while (formattedSize >= 1024 && unitIndex < units.length - 1) {
    formattedSize /= 1024;
    unitIndex += 1;
  }

  return `${formattedSize.toFixed(1)} ${units[unitIndex]}`;
}

export function getSellErrorMessages(obj: Record<string, any>) {
  const excludedNames = ['get', 'pay', 'description', 'audAmount'];
  const errorMessages: string[] = [];

  Object.keys(obj).forEach((key) => {
    if (!excludedNames.includes(key) && !!obj[key].message) {
      errorMessages.push(obj[key].message);
    }
  });

  return errorMessages;
}

export const isNumericValue = (inputValue: string) => {
  return /\d/.test(inputValue);
};

export const removeTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const Emoji = (props: { label?: string; symbol: string; className?: string }) => (
  <span
    className={props.className}
    role="img"
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
  >
    {props.symbol}
  </span>
);

export const normalizeBankDetailsData = (
  bankDetails: BankDetailsResponse | undefined,
  fieldName: keyof BankDetails,
) => {
  const details = bankDetails?.data.map((item, index) => {
    if (item[fieldName]) {
      return {
        value: item[fieldName],
        number: index,
      };
    }
    return undefined;
  });
  return details?.filter(Boolean);
};

export const redirectTo = (url: string) => {
  window.location.href = url;
};

export const formatNumberWithTwoDecimals = (num: string) => {
  if (num && num.toString().includes('.')) {
    return num.replace(/[.,]\d{3,}/g, (match) => match.slice(0, 3));
  }
  return undefined;
};

export const getUniqueArrayValues = (array: any) => {
  // Create a Set to keep track of unique addresses
  const seenAddresses = new Set();

  // Use the filter method to only keep unique addresses
  return array.filter((item: any) => {
    if (seenAddresses.has(item.address)) {
      // If address is already in the Set, exclude this item
      return false;
    }
    // If address is not in the Set, include this item and add the address to the Set
    seenAddresses.add(item.address);
    return true;
  });
};

export const IsERC20Network = (network?: CryptoCurrency) => {
  return walletConnectSupportList.includes(network || '');
};

export const isEVMNetwork = (chains: ChainData[], selectedNetwork?: CryptoCurrency) => {
  if (!selectedNetwork) return false;
  return chains.filter((chain) => chain.token === selectedNetwork).length > 0;
};

// Utility to manage cookies (or use localStorage if preferred)
export function setCookie(name: string, value: string, days: number): void {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
}

export function getCookie(name: string): string | null {
  const result = document.cookie.split('; ').reduce((acc, cookie) => {
    const [cookieName, cookieValue] = cookie.split('=');
    return cookieName === name ? decodeURIComponent(cookieValue) : acc;
  }, '');

  return result || null;
}

export function compareChainId(chainId1: string, chainId2: string) {
  // Convert both values to decimal for comparison
  const toDecimal = (id: string) => (typeof id === 'string' && id.startsWith('0x') ? parseInt(id, 16) : Number(id));

  return toDecimal(chainId1) === toDecimal(chainId2);
}
