import { create } from 'zustand';
import { subscribeWithSelector, persist } from 'zustand/middleware';
import { SellCryptoPaymentType } from '@/constants/payments';
import { BankType } from '@/constants/banks';
import { CryptoCurrency, FiatCurrency, PaymentType, SellCryptoTxResponse } from '@/api/common.types';
import { MerchantTxnResponse } from '@/api/queryHooks/useMerchantCryptoController';

interface SellCryptoStoreActionsInterface {
  reset: () => void;
  setData: (data: Partial<SellCryptoStoreInterface>) => void;
  setPayment: (paymentType: SellCryptoPaymentType) => void;
  getData: () => SellCryptoStoreInterface;
}

interface CombinedTxDataResponse extends SellCryptoTxResponse, MerchantTxnResponse {}

export interface SellCryptoStoreInterface {
  bankType: BankType;
  paymentType: SellCryptoPaymentType;
  get: string;
  pay: CryptoCurrency;
  description: string;
  accountName: string;
  BSB: string;
  accountNumber: string;
  referenceNumber: string;
  cardNumber: string;
  cardAmount: string;
  billerCode: string;
  IBAN: string;
  BICSWIFT: string;
  routingNumber: string;
  billInvoice: string;
  email: string;
  network: string;
  rate: number | null;
  agree: string;
  isConnectedWallet: boolean | null;

  txData: CombinedTxDataResponse | null;
  country: string;
  fiat: string;
  cryptoAmount: string;
  merchantId: string;
  merchantIndependentReserve: boolean;
  merchant: string;
  payoutType?: PaymentType;
  fiatCurrency?: FiatCurrency;
  userWalletAddress?: string;
  accountId?: string;
  surname?: string;
  actualAmountSent?: null | string;
}

const initialState: SellCryptoStoreInterface = {
  bankType: 'euro',
  paymentType: 'Bank Transfer',
  get: '',
  pay: CryptoCurrency.ETH,
  description: '',
  accountName: '',
  referenceNumber: '',
  cardNumber: '',
  cardAmount: '',
  BSB: '',
  accountNumber: '',
  billerCode: '',
  IBAN: '',
  BICSWIFT: '',
  routingNumber: '',
  billInvoice: '',
  email: '',
  network: '',
  rate: null,
  agree: '',
  txData: null,
  country: '',
  fiat: '',
  cryptoAmount: '',
  merchantId: '',
  merchantIndependentReserve: false,
  merchant: '',
  userWalletAddress: '',
  actualAmountSent: null,
  isConnectedWallet: null,
};

export const useSellCrypto = create(
  subscribeWithSelector(
    persist<SellCryptoStoreInterface & SellCryptoStoreActionsInterface>(
      (set, get) => ({
        ...initialState,
        setData(state: Partial<SellCryptoStoreInterface>) {
          set(() => ({ ...state }));
        },
        setPayment: (paymentType) => {
          set({ paymentType });
        },
        reset() {
          set(() => ({ ...initialState }));
        },
        getData() {
          return get();
        },
      }),
      {
        name: 'sellCrypto',
      },
    ),
  ),
);

useSellCrypto.getState().reset();
